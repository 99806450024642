import React, { Component } from "react";
import { Link } from "react-router-dom";

import { get } from "lodash";

import ListLoader from "../../others/components/ListLoader";
import { formatDateTime } from "../../others/utils";

import Urls from "../../UrlConfig";

export default class VehicleExpenseList extends Component {
  /**
   * Parent:
   * 		VehicleDetailsPage
   */

  render = () => {
    const { loading, data, error, count } = this.props;

    let table;

    if (loading) {
      table = <ListLoader row={count} col={3} />;
    } else if (error) {
      table = (
        <div className="list-empty-wrapper error">Failed to fetch data!!</div>
      );
    } else {
      if (data.length) {
        table = data.map((expense) => {
          return (
            <Link
              to={Urls.getExpenseEditPage(expense.id)}
              className="list-row"
              key={expense.id}
            >
              <div className="list-col">
                {formatDateTime(new Date(expense.timestamp))}
              </div>
              <div className="list-col">
                {get(expense, "expense_type.name", "-NA-")}
              </div>
              <div className="list-col">{expense.cost}</div>
            </Link>
          );
        });
      } else {
        table = <div className="list-empty-wrapper">No Expense Found</div>;
      }
    }

    return (
      <div className="list-container fix-table z-depth-1">
        <div className="list-row header-col">
          <div className="list-col">Time</div>
          <div className="list-col">Expense Type</div>
          <div className="list-col">Cost</div>
        </div>

        {table}
      </div>
    );
  };
}
