import React from "react";
import { Switch, Route } from "react-router-dom";

import DriverAddPage from "./pages/DriverAddPage";
import DriverResetPass from "./pages/DriverResetPass";
import DriverDetailsPage from "./pages/DriverDetailsPage";
import DriverListWrapperPage from "./pages/DriverListWrapperPage";
import ExpensePage from "../expense/pages/ExpensePage";
import ExpenseListPage from "../expense/pages/ExpenseListPage";

import PageNotFound from "../others/pages/PageNotFound";

import Urls from "../UrlConfig";

export default () => {
  /**
   * Parent :
   *      App
   */

  return (
    <Switch>
      <Route
        exact
        path={Urls.getDriverPage()}
        component={DriverListWrapperPage}
      />
      <Route path={Urls.getDriverAddPage()} component={DriverAddPage} />
      <Route path={Urls.getDriverEditPage()} component={DriverAddPage} />
      <Route path={Urls.getDriverDetails()} component={DriverDetailsPage} />
      <Route path={Urls.getDriverExpense()} component={ExpenseListPage} />
      <Route path={Urls.getExpenseAddPage()} component={ExpensePage} />
      <Route path={Urls.getDriverResetPass()} component={DriverResetPass} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};
