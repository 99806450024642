import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { get } from "lodash";

import ListLoader from "../../others/components/ListLoader";

import Urls from "../../UrlConfig";

export default ({ data, count, loading, error }) => {
  /**
   * show loading
   * render list
   *
   * Parent
   * 		VehicleListWrapperPage
   */

  const isSuperuser = useSelector((store) => store.auth.is_superuser);

  let table;
  const colCount = isSuperuser ? 7 : 6;

  if (loading) {
    table = <ListLoader row={count} col={colCount} />;
  } else if (error) {
    table = (
      <div className="list-empty-wrapper error">Failed to fetch data!!</div>
    );
  } else {
    if (data.length) {
      table = data.map((edge) => {
        const { cursor, node } = edge;

        return (
          <div className="list-row" key={cursor}>
            <div className="list-col">{node.name}</div>
            <div className="list-col">{node.model_no}</div>
            <div className="list-col">{node.registration_no}</div>
            <div className="list-col">{node.manufacturing_year}</div>
            <div className="list-col">{get(node, "driver.name", "-NA-")}</div>

            <div className="list-col action-col group-action-col">
              <div className="ga-first-col">
                <div className="ga-first-col-row">
                  <Link
                    to={Urls.getExpenseAddPage("vehicle", node.pk)}
                    className="yellow-btn action-btn"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>Expense
                  </Link>
                </div>
                <div className="ga-second-col-row">
                  <Link
                    to={Urls.getVehicleDetails(node.pk)}
                    className="yellow-btn action-btn"
                  >
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                    View
                  </Link>
                </div>
              </div>
              <div className="ga-second-col">
                <Link
                  to={Urls.getVehicleExpense(node.pk, "vehicle")}
                  className="yellow-btn action-btn"
                >
                  <i className="fa fa-list" aria-hidden="true"></i>
                  View Expenses
                </Link>
              </div>
            </div>
            {/* {isSuperuser && (
              <div className="list-col action-col">
                <Link
                  to={Urls.getExpenseAddPage("vehicle", node.pk)}
                  className="yellow-btn action-btn"
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>Expense
                </Link>
              </div>
            )}

            <div className="list-col action-col pd-l-0">
              <Link
                to={Urls.getVehicleDetails(node.pk)}
                className="yellow-btn action-btn"
              >
                <i className="fa fa-external-link" aria-hidden="true"></i>View
              </Link>
            </div> */}
          </div>
        );
      });
    } else {
      table = <div className="list-empty-wrapper">No Vehicle Found</div>;
    }
  }

  return (
    <div className="list-container">
      <div className="list-row">
        <div className="list-col">Name</div>
        <div className="list-col">Model No</div>
        <div className="list-col">Registration No</div>
        <div className="list-col">Manufacturing year</div>
        <div className="list-col">Driver</div>
        <div className="list-col"></div>
      </div>

      {table}
    </div>
  );
};
