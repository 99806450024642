import { postJsonData } from '../../others/utils'
import Urls from '../../UrlConfig'

export const updateFormField = (data) => ({
  type: "UPDATE_REPORT_FORM_FIELDS",
  payload: data
})

export const getExpenseData = (data) => {

  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING' })

    postJsonData(Urls.apiExpenseReport(),
      data,
      (res) => {
        dispatch({ type: 'EXPENSE_FETCH_DATA_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'REPORT_DATA_FETCH_ERROR', payload: err })
      }
    )
  }
}


export const getTripData = (data) => {

  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING' })

    postJsonData(Urls.apiTripReport(),
      data,
      (res) => {
        dispatch({ type: 'TRIP_DATA_FETCH_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'REPORT_DATA_FETCH_ERROR', payload: err })
      }
    )
  }
}

export const getVehicleRefuleData = (data) => {

  return async dispatch => {
    dispatch({ type: 'REPORT_DATA_FETCHING' })

    postJsonData(Urls.apiVehicleRefuelReport(),
      data,
      (res) => {
        dispatch({ type: 'VEHICLE_REFULE_DATA_FETCH_SUCCESS', payload: res })
      },
      (err) => {
        // console.log("-> err", err)
        dispatch({ type: 'REPORT_DATA_FETCH_ERROR', payload: err })
      }
    )
  }
}