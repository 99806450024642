import map from "lodash/map";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import groupBy from "lodash/groupBy";

/**
 * Convert data from db into data to be represented in AG grid
 * Output shape : [ {date, [e_type_1] : amount, [e_type_2] : amount, ...} , ... ]
 *
 * @param {Array} expenseList shape : [ {day, e_type, amount, refuel_quantity}, ...]
 */
export const transformExpenseData = (expenseList) => {
  const updatedExpenseList = map(expenseList, (data, index) => ({
    ...data,
    index,
  }));
  const dieselExpenseList = filter(updatedExpenseList, ["e_type", "DIESEL"]);

  // new object : { day : [ all objects of same day ] , ... }
  let dayWiseExp = groupBy(updatedExpenseList, (data) => {
    return data.day;
  });
  let result = [];
  for (const key in dayWiseExp) {
    if (dayWiseExp.hasOwnProperty(key)) {
      const currExp = dayWiseExp[key];
      // add date
      let newDatum = { date: new Date(key) };
      for (let index = 0; index < currExp.length; index++) {
        // convert each e_type : label -> [label] : amount
        const singleDatum = currExp[index];
        newDatum[singleDatum.e_type] = singleDatum.amount;
        if (singleDatum.e_type === "DIESEL") {
          newDatum["refuel_q"] = singleDatum.refuel_q.toFixed(2);
          updateFuelAverageData(dieselExpenseList, singleDatum, newDatum);
        }
      }
      result.push(newDatum);
    }
  }
  return result;
};

const updateFuelAverageData = (dieselExpenseList, singleDatum, newDatum) => {
  const currEntryIndex = findIndex(dieselExpenseList, [
    "index",
    singleDatum.index,
  ]);
  const prevEntry = dieselExpenseList[currEntryIndex - 1];
  if (prevEntry) {
    const distance = singleDatum.total_km_reading - prevEntry.total_km_reading;
    const fuelAverage = distance / singleDatum.refuel_q;
    newDatum["fuel_average"] = fuelAverage.toFixed(2);
  }
};

export const getStatusName = (status, isPostponed) => {
  if (isPostponed) {
    return "Postponed";
  }
  return {
    U: "Upcoming",
    O: "Ongoing",
    C: "Completed",
  }[status];
};
