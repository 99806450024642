export default class Urls {
  ///////////////////////////////////
  //			Web Urls			//
  /////////////////////////////////

  static getHomePage() {
    return "/";
  }

  static getDashboardPage() {
    return "/dashboard";
  }

  // ----------- Vehicle Urls -----------

  static getVehiclePage() {
    return "/vehicle";
  }

  static getVehicleDetails(vehicleId = ":vehicleId") {
    return `/vehicle/details/${vehicleId}`;
  }

  static getVehicleExpense(id = ":id", entityType= ":entityType") {
    return `/${entityType}/expense/${id}`;
  }

  static getVehicleAddPage() {
    return "/vehicle/add";
  }

  static getVehicleEditPage(vehicleId = ":vehicleId") {
    return `/vehicle/edit/${vehicleId}`;
  }

  // ----------- Trip Urls -----------

  static getTripPage() {
    return "/trip";
  }

  static getTripDetails(tripId = ":tripId") {
    return `/trip/details/${tripId}`;
  }

  static getTripAddPage() {
    return "/trip/add";
  }

  // ----------- clients Urls -----------

  static getClientPage() {
    return "/clients";
  }

  static getClientFormPage(clientId = ":clientId") {
    return `/clients/update/${clientId}`;
  }

  // ----------- Report Urls -----------

  static getReportPage() {
    return "/report";
  }

  // ----------- Expense Urls -----------

  static getExpenseAddPage(
    entity_type = ":entity_type",
    entity_id = ":entity_id"
  ) {
    return `/${entity_type}/${entity_id}/expense/add`;
  }

  static getExpenseEditPage(expense_id = ":expense_id") {
    return `/expense/edit/${expense_id}`;
  }

  // ----------- Refuel Urls -----------

  static getRefuelAddPage() {
    return `/vehicle/refuel/add`;
  }

  // ----------- Driver Urls -----------

  static getDriverPage() {
    return "/driver";
  }

  static getDriverAddPage() {
    return "/driver/add";
  }

  static getDriverEditPage(driverId = ":driverId") {
    return `/driver/edit/${driverId}`;
  }

  static getDriverDetails(id = ":id") {
    return `/driver/details/${id}`;
  }

  static getDriverExpense(id = ":id", entityType = ":entityType") {
    return `/${entityType}/expense/${id}`;
  }

  static getDriverResetPass() {
    return `/driver/reset-password`;
  }

  // ----------- Auth Urls -----------

  static getAuthPage(auth_type = ":auth_type") {
    // auth_type : login | signup | forgotpassword
    return `/auth/${auth_type}`;
  }

  // ----------- Profile Urls -----------

  static getProfilePage() {
    return "/profile";
  }

  // Other urls
  static get404() {
    return "/404";
  }

  static getUnauthorized() {
    return "/unauthorized";
  }

  static getPrivacyPage() {
    return "/privacy-policy";
  }

  ///////////////////////////////////
  //			API Urls			//
  /////////////////////////////////

  static apiTokenCheck() {
    return `/api/user/token/verify/`;
  }

  static apiAuthLogin() {
    return "/api/user/login/";
  }

  static apiChangePassword() {
    return "/api/user/change/password/";
  }

  static apiDriverResetPassword() {
    return "/api/user/reset/driver/password/";
  }

  static apiGetClientList() {
    return "/api/user/client-list/";
  }

  static apiCreateClient() {
    return "/api/user/create-client/";
  }

  static apiClientDetails(clientId = ":clientId") {
    return `/api/user/client-details/${clientId}/`;
  }

  static apiClientDelete(clientId = ":clientId") {
    return `/api/user/client-delete/${clientId}/`;
  }

  // ----------- Driver Api ---------

  static apiDriverAdd() {
    return "/api/driver/add/";
  }

  // ----------- Vehicle Api ---------

  static apiVehicleAdd() {
    return "/api/vehicle/add/";
  }

  static apiVehicleExpenses(vehicle_id, start_date, end_date) {
    if (start_date && end_date) {
      return `/api/vehicle/expenses/${vehicle_id}/?start_date=${start_date}&end_date=${end_date}`;
    } else {
      return `/api/vehicle/expenses/${vehicle_id}/`;
    }
  }

  // ----------- Trip Api ---------

  static apiTripAdd() {
    return "/api/trip/add/";
  }

  static apiTripDelete(tripId = ":tripId") {
    return `/api/trip/delete/${tripId}/`;
  }

  static apiTripStatusChange(tripId = ":tripId") {
    return `/api/trip/change-status/${tripId}/`;
  }

  static apiTripList() {
    return "/api/trip/list/";
  }

  static apiTripReport() {
    return "/api/trip/report/";
  }

  // ----------- Expense Api ---------

  static apiExpenseAdd() {
    return "/api/expense/add/";
  }

  static apiExpenseEdit(expenseId = ":expenseId") {
    return `/api/expense/edit/${expenseId}/`;
  }

  static apiExpenseReport() {
    return "/api/expense/report/";
  }

  static apiExpenseReportExcel() {
    return "/api/expense/report/excel/";
  }

  static apiRefuelAdd() {
    return "/api/expense/refuel/add/";
  }

  static apiVehicleRefuelReport() {
    return "/api/expense/vehicle-refule/";
  }

  // image url
  static getImage(image_url) {
    if (image_url) {
      return `/media/${image_url}`;
    } else {
      return `/assets/img/img_placeholder.png`;
    }
  }

  static getStaticImg(image_url = ":null") {
    // return `/static/${image_url}`
    // currently postbuild script changes all "static/" to "assets/" which will require this fix
    return `/assets/${image_url}`;
  }

  static getGraphqlUrl() {
    return "/graphql/";
  }
}
