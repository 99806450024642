import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { DateTimeRangePickerInput } from "react-datetime-range-super-picker";

import get from "lodash/get";

import { startOfToday, addHours, subDays } from "date-fns";

import ReportAGTable from "../../reports/components/ReportAGTable";
import Loader from "../../others/components/Loader";

import { formatSubmitDate } from "../../others/utils";
import { getExpenseData } from "../../reports/data/reports.action";
import { GET_REPORT_CHOICES } from "../../reports/data/reports.graphql";

const ExpenseListPage = () => {
  const dispatch = useDispatch();
  let { entityType, id } = useParams();

  const {
    loading,
    data: reportChoices,
    error: gqlError,
  } = useQuery(GET_REPORT_CHOICES, {
    fetchPolicy: "cache-and-network",
    onCompleted:() => {
      handleSubmit();
    }
  });

  const [startDate, setStartDate] = useState(subDays(startOfToday(), 60));
  const [endDate, setEndDate] = useState(addHours(startOfToday(), 23));
  const reportData = useSelector((store) => store.reports);
  const {
    fetched,
    error,
    fetching,
    expense_by_day,
    expense_by_type,
    expense_full_data,
  } = reportData;

  const handleFromDateUpdate = ({ date }) => {
    setStartDate(date.date);
  };

  const handleToDateUpdate = ({ date }) => {
    setEndDate(date.date);
  };

  const handleSubmit = () => {
    let postData = {
      start_date: formatSubmitDate(startDate),
      end_date: formatSubmitDate(endDate),
    };

    if (entityType === "vehicle") {
      postData["report_type"] = "vehicle";
      postData["vehicle_ids"] = [Number(id)];
    }
    //
    else if (entityType === "driver") {
      postData["report_type"] = "driver";
      postData["driver_ids"] = [Number(id)];
    }
    dispatch(getExpenseData(postData));
  };

  const expensesTypeList = get(reportChoices, "expenses_type_list.edges", []);

  return (
    <div className="page-container">
      <div className="page-title">Expenses</div>
      <div className="search-wrapper">
        <div className="search-input-container">
          <DateTimeRangePickerInput
            from_date={startDate}
            to_date={endDate}
            onFromDateTimeUpdate={handleFromDateUpdate}
            onToDateTimeUpdate={handleToDateUpdate}
            theme="dark"
            className="range-picker textbox search-input"
            popupClassName="range-picker-popup"
            inputStyle={{
              backgroundColor: "#fff",
            }}
          />
        </div>
        <div
          className="btn-container"
          style={{ minWidth: "10em" }}
          onClick={handleSubmit}
        >
          <div className="yellow-btn">Submit</div>
        </div>
      </div>

      <div
        id="report-container"
        className="report-container"
        style={{ height: "calc(100% - 100px)", overflow: "auto" }}
      >
        {fetched ? (
          <div
            className="report-data-container z-depth-1"
            style={{ marginTop: 0, minHeight: "100%" }}
          >
            <ReportAGTable
              data={expense_full_data}
              expense_by_day={expense_by_day}
              expense_by_type={expense_by_type}
              expensesTypeList={expensesTypeList}
              showHeading={false}
              showAvgColumn={true}
            />
          </div>
        ) : error || gqlError ? (
          <div
            className="report-data-container z-depth-1"
            style={{ marginTop: 0, minHeight: "100%" }}
          >
            <div className="reports-chart-container error-container">
              <h2>Failed to fetch data</h2>
            </div>
          </div>
        ) : fetching || loading ? (
          <Loader />
        ) : null}
      </div>
    </div>
  );
};

export default ExpenseListPage;
