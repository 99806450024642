import React from "react";
import { connect } from "react-redux";

import size from "lodash/size";

import BarChartWrapper from "./BarChartWrapper";
import PieChartWrapper from "./PieChartWrapper";
import ReportAGTable from "./ReportAGTable";

export default connect((store) => ({
  reports: store.reports,
}))((props) => {
  /**
   * Parent:
   *      ReportPage
   * Renders:
   *      BarChartWrapper
   *      PieChartWrapper
   *      ReportAGTable
   */
  const { expense_by_day, expense_by_type, expense_full_data, selectedVehicle, specificVehicleSelected  } = props.reports;
  const { expensesTypeList } = props;

  return (
    <div className="report-data-container z-depth-1">
      <div className="reports-chart-container flex-wrapper">
        <div className="flex-sm bar-chart-wrapper">
          <div className="heading">Day wise expense</div>
          <BarChartWrapper data={expense_by_day} />
        </div>
        <div className="flex-sm">
          <div className="heading">Type wise expense</div>
          <PieChartWrapper data={expense_by_type} />
        </div>
      </div>

      <ReportAGTable
        data={expense_full_data}
        expense_by_day={expense_by_day}
        expense_by_type={expense_by_type}
        expensesTypeList={expensesTypeList}
        showAvgColumn={specificVehicleSelected && !!size(selectedVehicle)}
      />
    </div>
  );
});
